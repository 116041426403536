import { createSlice } from "@reduxjs/toolkit";
import { getFormattedDevice } from "../helpers/backendDataFormatting";

const initialState = {
  devicesByUuid: {},
  allDeviceUuids: [],
};

const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    // Each time devices are fetched in session
    // This will run and updated relevant state
    initialDevicesFetched(state, action) {
      state.allDeviceUuids = [];
      const devices = action.payload;
      devices?.forEach((device) => {
        const { uuid } = device;
        state.devicesByUuid[uuid] = getFormattedDevice(device);
        state.allDeviceUuids.push(uuid);
      });
    },
  },
});

export const { initialDevicesFetched } = devicesSlice.actions;

export default devicesSlice.reducer;
