import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedUuids: [],
};

const deviceSelectionSlice = createSlice({
  name: "deviceSelection",
  initialState,
  reducers: {
    itemsSelected(state, action) {
      state.selectedUuids = action.payload;
    },
    selectionsCleared(state) {
      state.selectedUuids = [];
    },
    selectionToggled(state, action) {
      const itemIsSelected = state.selectedUuids.includes(action.payload);
      if (itemIsSelected) {
        // Uncheck the box
        state.selectedUuids = state.selectedUuids.filter(
          (uuid) => uuid !== action.payload
        );
      } else {
        // Check the box
        state.selectedUuids = [
          ...new Set([...state.selectedUuids, action.payload]),
        ];
      }
    },
  },
});

export const { selectionsCleared, itemsSelected, selectionToggled } =
  deviceSelectionSlice.actions;

export default deviceSelectionSlice.reducer;
