/*
 * Backend Data Formatting
 *
 * All of the methods here alter device data as it comes in
 * from the backend. This stuff should actually be happening
 * on the backend but for now, we're putting it all in this
 * one spot. We should never ever manipulate device data in
 * state directly (device.property = "X") after this point
 * in the code.
 *
 * Add any new formatting functions to formattingFunctions
 * array. They should pass a device in and return the device.
 * They will be automatically ran in the last method in the
 * file, getFormattedDevice.
 *
 * NOTE: Before adding anything else to this file, consider
 * making a subtask for your ticket and asking a backend dev
 * to add this bit of code to the backend so that devices
 * return the exact data in the exact shape you need.
 */

export const addProductIdToDeviceElectron = (device) => {
  // Currently, we use these properties to determine check-in
  // range/status and DeviceElectron is sometimes used as device
  // target. Adding here if DeviceElectron doesn't exist.
  if (!device?.DeviceElectron) {
    if (device?.ProductId === "10") {
      device.DeviceElectron = {
        ProductId: device?.ProductId,
        checkedInAt: device.checkedInAt,
        status: device?.status,
      };
    }
  } else {
    device.DeviceElectron.ProductId = device?.ProductId;
  }
  return device;
};

export const addGenericAliasToDevice = (device) => {
  const productName = device?.Product?.name;
  const serialNumber = device?.serial ? ` - ${device?.serial}` : "";
  const genericDeviceName = `${productName}${serialNumber}`;
  if (device?.alias === null || device?.alias === "") {
    device.alias = genericDeviceName;
  }
  return device;
};

// This seems to have been done just for convenience
export const addOptedOutToDevice = (device) => {
  device.optedOut = device?.setting?.dataSharing === "UserOptedOut";
  return device;
};

/*
 * formattingFunctions
 *
 * Add any new formatting functions to this array. They
 * will be automatically ran in the getFormattedDevice
 * method below.
 */
const formattingFunctions = [
  addProductIdToDeviceElectron,
  addGenericAliasToDevice,
  addOptedOutToDevice,
];

/*
 * ----------------------------------------------------
 *   YOU SHOULD NOT NEED TO CHANGE THE BELOW FUNCTION
 * ----------------------------------------------------
 */
export const getFormattedDevice = (device) => {
  let formattedDevice = { ...device };
  formattingFunctions.forEach((fn) => {
    formattedDevice = fn(formattedDevice);
  });
  return formattedDevice;
};
