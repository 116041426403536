import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ascending: null,
  defaultSortBy: "alias",
  currentSortBy: null,
  naturalSortKeys: ["alias", "hardwareSerial", "softwareVersion", "location"],
};

const deviceSortSlice = createSlice({
  name: "deviceSort",
  initialState,
  reducers: {
    ascendingSet(state, action) {
      state.ascending = action.payload;
    },
    currentSortBySet(state, action) {
      state.currentSortBy = action.payload;
    },
  },
});

export const { ascendingSet, currentSortBySet } = deviceSortSlice.actions;

export default deviceSortSlice.reducer;
