import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: {
    alias: false,
    analytics: false,
    command: false,
    details: false,
  },
};

const deviceModalSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    modalToggled(state, action) {
      const modal = action.payload;
      state.isOpen[modal] = !state.isOpen[modal];
    },
  },
});

export const { modalToggled } = deviceModalSlice.actions;

export default deviceModalSlice.reducer;
