import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Filters that are actively affecting the device list
  activeFilters: {
    // Filter categories
    deviceType: [],
    deviceLocation: [],
    deviceStatus: [],
  },

  // Boolean that actively affects device list
  filterByUpdateNeededActive: false,
};

const deviceFilterSlice = createSlice({
  name: "deviceFilter",
  initialState,
  reducers: {
    filtersApplied(state, action) {
      const { selectedFilters, filterByUpdateNeeded } = action.payload;
      state.activeFilters = selectedFilters;
      state.filterByUpdateNeededActive = filterByUpdateNeeded;
    },
  },
});

export const { filtersApplied } = deviceFilterSlice.actions;

export default deviceFilterSlice.reducer;
