import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './redux/store';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { retry } from './helpers/Utils'

(async () => {
const App = React.lazy(() => retry(() => import(/* webpackChunkName: "App" */'./App' )));

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_KEY,
    reactOptions: {
      useCamelCaseFlagKeys: false
    }
  });

  ReactDOM.render(
    <Provider store={store}>
      <LDProvider>
      <Suspense fallback={<div className="loading" />}>
        <App />
      </Suspense>
      </LDProvider>
    </Provider>,
    document.getElementById('root')
  );

  /*
  * If you want your app to work offline and load faster, you can change
  * unregister() to register() below. Note this comes with some pitfalls.
  * Learn more about service workers: https://bit.ly/CRA-PWA
  */
  serviceWorker.unregister();
})();