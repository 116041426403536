import {
    TEAM_NEW_USER_LOGIN
} from '../actions';

const INIT_STATE = {
    newUserEmailFromNotification : ""
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TEAM_NEW_USER_LOGIN:
    return { ...state, newUserEmailFromNotification: action.payload.email};
    default: return { ...state };
  }
}

export default reducer;
