import Cookies from "universal-cookie";

const cookies = new Cookies();

export const createCommandHistory = async (device, user, command) => {
  try {
    const userCommandHistoryResource = await import(
      "../resources/userCommands"
    );

    const res =
      await userCommandHistoryResource.default.createUserCommandHistory({
        data: {
          deviceUUID: device.uuid,
          UserId: user.id,
          command,
          softwareVersion: device.softwareVersion,
        },
      });
    return res.data;
  } catch (err) {
    const toast = await import("../components/common/Toast");
    toast.default.warning(
      "Sorry, something went wrong and we can't process your request"
    );
  }
};

export const sendCommand = async (device, cmd, args, commandHistory) => {
  let resp;
  try {
    let payload = { data: { cmd, mac_address: device?.macAddress } };
    if (args) {
      payload.data.args = args;
    }
    const iot = await import("../resources/iotCommands");
    resp = await iot.default.command(payload);
    return resp.data;
  } catch (err) {
    const toast = await import("../components/common/Toast");
    const userCommandHistoryResource = await import(
      "../resources/userCommands"
    );
    toast.default.warning(
      "Sorry, we were not able to get a response from the Owl"
    );
    await userCommandHistoryResource.default.updateUserCommandHistory({
      params: { id: commandHistory.id },
      data: { success: false },
    });
    return null;
  }
};

export const sendCommandAndCreateHistory = async (
  device,
  user,
  command,
  args
) => {
  const userCommandHistoryResource = await import("../resources/userCommands");
  const commandHistoryResponse =
    await userCommandHistoryResource.default.createUserCommandHistory({
      data: {
        deviceUUID: device.uuid,
        UserId: user.id,
        command,
        softwareVersion: device.softwareVersion,
      },
    });
  const commandHistory = commandHistoryResponse.data;

  try {
    let payload = { data: { cmd: command, mac_address: device?.macAddress } };
    if (args) {
      payload.data.args = args;
    }
    const iot = await import("../resources/iotCommands");
    const resp = await iot.default.command(payload);
    return resp.data;
  } catch (err) {
    await userCommandHistoryResource.default.updateUserCommandHistory({
      params: { id: commandHistory.id },
      data: { success: false },
    });
    return null;
  }
};

export const getDeviceTarget = (device, backend = false) => {
  let target = device;
  if (device?.Product?.sku === "FLM") {
    target = backend ? device : device.DeviceElectron;
  }
  return target;
};

// Simple email pattern for catching most typos client-side
// Only one @, at least one char before @, before period
// and after period, and no whitespace
export const validateEmail = (email) => {
  const simpleEmailPattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
  return email.trim().match(simpleEmailPattern);
};

export const getDomainFromEmail = (email) => {
  return email.toLowerCase().split("@")[1];
};

export const sortByCreatedAt = (a, b) => {
  return a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0;
};

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

/*   applySearchFilter takes three parameters. It will typically be called within a setState function, eg, setFilteredDevices(applySearchFilter(items,searchableFields,searchterms))
 *   @items is an array of objects to search through
 *   @searchableFields is an array of strings that represent the array keys in the items to search. You should only use keys for arrays that use the 'string' type. eg ["name", "hardwareSerial"]
 *   @searchTerms is the array of search terms you are passing.
 */
export const applySearchFilter = ({ items, searchableFields, searchTerms }) => {
  return searchTerms.length === 0
    ? items
    : items.filter((item) => {
        const searchables = [];
        searchableFields.forEach((searchableField) => {
          searchables.push(item[searchableField]);
        });

        return searchables.some((s) =>
          searchTerms.some(
            (term) => (s || "").toLowerCase().indexOf(term) !== -1
          )
        );
      });
};
// clear search field
export const clearField = (e) => {
  if (e.target) {
    // If the field being cleared is the same field that fired the event.
    e.target.value = "";
  } else {
    // If the field being cleared is NOT the same field that fired the event (for example, if you need to clear a parentNode of a clicked element.)
    e.value = "";
  }
  clearUrlQueryParams();
};

// Update the url to include query param and set the search terms based on that URL.
export const updateUrlString = (key, value) => {
  const url = new URL(window.location);
  if (value === "") {
    url.searchParams.set(key, value);
  } else {
    value.forEach((singleValue, index) => {
      if (index === 0) {
        url.searchParams.set(key, singleValue);
      } else {
        url.searchParams.append(key, singleValue);
      }
    });
  }

  window.history.pushState(null, "", url.toString());
};

export const clearUrlQueryParams = () => {
  const url = new URL(window.location);
  window.history.pushState(null, "", url?.origin + url?.pathname);
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "." + mm + "." + yyyy;
};

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes();
};

// Credit (with modifications) to https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
export function retry(workload, maxRetryAttempts = 7, retryCount = 0) {
  // Calculate exponential backoff + jitter, in milliseconds. First
  // retry should be in approx. 100ms.
  var expBackoff = Math.pow(2, retryCount) * 100;
  var maxJitter = Math.ceil(expBackoff * 0.2);
  var finalBackoff = expBackoff + Math.random(maxJitter);

  return new Promise((resolve, reject) => {
    workload()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retryCount >= maxRetryAttempts) {
            reject(error);
            return;
          }

          retry(workload, maxRetryAttempts, retryCount + 1).then(
            resolve,
            reject
          );
        }, finalBackoff);
      });
  });
}

/**
 * Sets a UniversalCookie to the max age allowed in the web browser.
 */
export function setLongLivedCookie(name, value, path = "/") {
  cookies.set(name, value, { path: path, maxAge: 2147483647 });
}

/*
 * This allows debuging one-statement functions:
 *
 *   [1, 2, 3].map(item => debug('map', item))
 *
 * An alternative to this would be changing the
 * one-statement function into a multi-statement one:
 *
 *    [1, 2, 3].map(item => {
 *      console.log('map', item)
 *      return item
 *    })
 *
 * This is obviously unnecessary, hence this function.
 *
 * # Usage
 *
 * ## With label
 *
 *   [1, 2, 3].map(item => debug('map', item))
 *
 * ## Without label
 *
 *   [1, 2, 3].map(item => debug(item))
 *
 */
export function debug(...args) {
  const [value, label] = args.reverse();

  if (process.env.NODE_ENV === "development") {
    console.log(`${label || "debug"}: ${JSON.stringify(value, null, 2)}`);
  }

  return value;
}

window.debug = debug;

/*
 * Wrapper for a function that logs its arguments as well as
 * any additional details at the time of its execution.
 *
 * Let's say we have this function:
 *
 *   const toggleModal = () => {
 *     isCodeValid ? closeModal() : modal.setters.setShown(!modal.state.shown)
 *   }
 *
 * We then use this function as a hook:
 *
 *   useEffect(() => {
 *     modal.helperSetters.setToggleModalFn(() => toggleModal)
 *   })
 *
 * Would we have a bug in this function, we would have no idea
 * if the function is called and whether it's called with the right arguments.
 *
 * And this is where the wrapper comes in. Let's wrap the function in debugFnCall:
 *
 *   const toggleModal = debugFnCall('toggleModal', () => {
 *     isCodeValid ? closeModal() : modal.setters.setShown(!modal.state.shown)
 *   }, {isCodeValid, modal_state_shown: modal.state.shown})
 *
 * Note that the definition has stayed the same, we only added a label and
 * variables that we want to see inspected when the function runs.
 *
 * And very importantly: this wrapper will print out all the arguments
 * the function receives as well.
 */
export function debugFnCall(label, fn, inspectValues = {}) {
  return (...args) => {
    const result = fn(...args);
    const values = Object.assign({ result, args }, inspectValues);

    try {
      //TODO: Debug functions are not allowed in updated react-testing-library. Consider rewrite this function to not utilize debug call
      //eslint-disable-next-line testing-library/no-debugging-utils
      debug(`Function ${label}`, values);
    } catch {
      // Some values cannot be serialised into JSON.
      console.log(`Function ${label}`, values);
    }

    return result;
  };
}

window.debugFnCall = debugFnCall;

export const resetMixpanel = async () => {
  // Reset Mixpanel's super properties
  const mixpanel = await import("mixpanel-browser");
  await mixpanel.reset();
};

export const logout = async () => {
  const loginResource = await import("../resources/login");
  await loginResource.default.logout();
};

// Wrapper for the logout function to include clearing session storage
export const logOutAndClearSession = async () => {
  sessionStorage.clear();
  await resetMixpanel();
  await logout();
};
