import { configureStore } from "@reduxjs/toolkit";

// Slices
import iot from "./iot";
import devices from "./devices";
import deviceFilter from "./deviceFilter";
import deviceSearch from "./deviceSearch";
import deviceModal from "./deviceModal";
import deviceSort from "./deviceSort";
import deviceSelection from "./deviceSelection";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import team from "./team/reducer";

export default configureStore({
  reducer: {
    iot,
    devices,
    deviceFilter,
    deviceSearch,
    deviceSort,
    deviceSelection,
    deviceModal,
    settings,
    menu,
    team,
  },
});
