import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchTerm: "",
  searchKeys: [
    { name: "alias", weight: 3 },
    { name: "location", weight: 2 },
    // These all have default weight of 1
    "hardwareSerial",
    "softwareVersionString",
    "ProductId",
  ],
  invalidSearchTermValues: ["", undefined, null, false],
};

const deviceSearchSlice = createSlice({
  name: "deviceSearch",
  initialState,
  reducers: {
    searchTermChanged(state, action) {
      state.searchTerm = action.payload;
    },
    searchCleared(state) {
      state.searchTerm = "";
    },
  },
});

export const { searchTermChanged, searchCleared } = deviceSearchSlice.actions;

export default deviceSearchSlice.reducer;
