import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-table/react-table.css';
import 'react-circular-progressbar/dist/styles.css';

import './assets/fonts/iconsmind-s/css/iconsminds.css';
import './assets/fonts/simple-line-icons/css/simple-line-icons.css';
import './assets/css/sass/themes/gogo.light.blue.scss';
import './index.css';

import * as serviceWorker from './serviceWorker';

require('./AppRenderer');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
